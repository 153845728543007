import { notDesktop, styled } from '@obvio/app'
import { RichText } from '@obvio/template'
import { Grid } from '@obvio/ui'

import { FloatImage } from './FloatImage'
import { ImagesWrap, ImgWrapHigh, ImgWrapLow } from './FullSection'

import type { ImageAsset } from '@obvio/app'
import type { ReactElement } from 'react'

const Wrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`

type RichTextImageProps = {
  description: string
  image: ImageAsset
  image2: ImageAsset
}

export function RichTextImage({
  description,
  image,
  image2,
}: RichTextImageProps): ReactElement {
  return (
    <Wrap templateColumns="50% 50%" gap="medium">
      <ImagesWrap>
        <div>
          <ImgWrapHigh>
            <FloatImage img={image} />
          </ImgWrapHigh>
        </div>
        <div>
          <ImgWrapLow>
            <FloatImage img={image2} />
          </ImgWrapLow>
        </div>
      </ImagesWrap>
      <div>
        <RichText value={description} />
      </div>
    </Wrap>
  )
}
